body {
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif,-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#background {
	background-color: #fffaf2 !important
}
.btn-floating i {
  color: #000 !important;
}

.horizontal-line{
	border-top: 1px dotted gray;
}

.image-css{
	max-width: 9.5rem;
	min-height: 5rem;
}

.row {
	margin-bottom: 0 !important;

}

.image-div{
	height: auto;
	display: flex;
	justify-content: flex-end;
	top:50%;
}

.product-line{
	display: flex;
	align-items: center; 
	justify-content: center;
}

.btn-remove {
	float: right;
}

img {
  max-height: 4em !important;
}

a {
  color: black !important; 
}
h4 {
	text-align: center;
	margin-bottom: 2rem;
}
table {
	text-align: center;
}
.nutri_nova {
	display: flex;
	justify-content: space-around;
	margin: auto;
	flex-direction: row;
	height: 130px;
}
/* .mon_image {
	display: flex;
	justify-content: flex-start;
	margin: auto
} */

.btn-save {
	margin-right: 5px;
}
.badge {
	max-height: 130px;
  /* max-width: 60px; */
}


.drawingBuffer {
  display: none;
}

#scanContainer {
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #ffffff,
}

#pointer {
  display: flex;
  justify-content: center;
  align-self: center;
  position: absolute;
  z-index: 4;
  border: 1.5px solid #ffff44;
  height: 40%;
  width: 80%;
  max-width: 540px;
}

.logo {
  position: absolute;
  height: 60px;
  width: 60px;
  margin-left: 4%;
  margin-top: 4%;
}

#interactive{
  display:flex;
  justify-content:center;
}

.history{
  display: none !important;
}
/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

