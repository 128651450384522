.btn-floating i {
  color: #000 !important;
}

.horizontal-line{
	border-top: 1px dotted gray;
}

.image-css{
	max-width: 9.5rem;
	min-height: 5rem;
}

.row {
	margin-bottom: 0 !important;

}

.image-div{
	height: auto;
	display: flex;
	justify-content: flex-end;
	top:50%;
}

.product-line{
	display: flex;
	align-items: center; 
	justify-content: center;
}

.btn-remove {
	float: right;
}
